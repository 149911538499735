.landing {
  background: 'black';
}
.landing-logo {
  z-index: 1001;
  animation: App-logo-spin infinite 60s linear;
  height: 20vmin;
}

.landing-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 1000;
}
.landing-header h1 {
  margin-top: 25px;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
  z-index: 1003;
}
.landing-link {
  color: #61dafb;
}
.privacy-link {
  color: 'white';
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} 

@keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-moz-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-ms-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-webkit-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-moz-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-ms-keyframes move-clouds-back {
  from {background-position: 0;}
  to {background-position:10000px 0;}
}

.stars, .twinkling, .clouds {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
height:100%;
display:block;
}

.stars {
background:#000 url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
z-index:0;
}

.twinkling{
background:transparent url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat top center;
z-index:1;

-moz-animation:move-twink-back 200s linear infinite;
-ms-animation:move-twink-back 200s linear infinite;
-o-animation:move-twink-back 200s linear infinite;
-webkit-animation:move-twink-back 200s linear infinite;
animation:move-twink-back 200s linear infinite;
}

.clouds{
  background:transparent url(http://www.script-tutorials.com/demos/360/images/clouds3.png) repeat top center;
  z-index:1002;

-moz-animation:move-clouds-back 200s linear infinite;
-ms-animation:move-clouds-back 200s linear infinite;
-o-animation:move-clouds-back 200s linear infinite;
-webkit-animation:move-clouds-back 200s linear infinite;
animation:move-clouds-back 200s linear infinite;
}