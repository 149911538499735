.App {
  text-align: center;
  background-color: #000000;
  animation: 6s ease-out 0s 1 onLoad;
}
@keyframes onLoad {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1
  }
}