* {
  margin: 0;
  padding: 0;
  background-color: 'black';
}
html {
  background-color: #000000;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background-color: #000000;
  -webkit-animation: 6s ease-out 0s 1 onLoad;
          animation: 6s ease-out 0s 1 onLoad;
}
@-webkit-keyframes onLoad {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1
  }
}
@keyframes onLoad {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1
  }
}
.footer-bar {
  z-index: 1004;
  padding-bottom: 2vmin;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: space-around;
  font-size: .6em;
}
.footer-landing {
  position: absolute;
  bottom: 1%;
  left: 0;
  right: 0;
}
.footer-link {
  color: greenyellow;
  text-decoration: none;
}
.footer-link:hover {
  color: rgb(153, 255, 0);
  text-shadow: 0 0 5px #eeff00;
}
.footer-link:active {
  color: rgb(123, 165, 60);
}
.landing {
  background: 'black';
}
.landing-logo {
  z-index: 1001;
  -webkit-animation: App-logo-spin infinite 60s linear;
          animation: App-logo-spin infinite 60s linear;
  height: 20vmin;
}

.landing-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 1000;
}
.landing-header h1 {
  margin-top: 25px;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
  z-index: 1003;
}
.landing-link {
  color: #61dafb;
}
.privacy-link {
  color: 'white';
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
} 

@keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}
@-webkit-keyframes move-twink-back {
  from {background-position:0 0;}
  to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}
@-webkit-keyframes move-clouds-back {
  from {background-position:0 0;}
  to {background-position:10000px 0;}
}

.stars, .twinkling, .clouds {
position:absolute;
top:0;
left:0;
right:0;
bottom:0;
width:100%;
height:100%;
display:block;
}

.stars {
background:#000 url(http://www.script-tutorials.com/demos/360/images/stars.png) repeat top center;
z-index:0;
}

.twinkling{
background:transparent url(http://www.script-tutorials.com/demos/360/images/twinkling.png) repeat top center;
z-index:1;
-webkit-animation:move-twink-back 200s linear infinite;
animation:move-twink-back 200s linear infinite;
}

.clouds{
  background:transparent url(http://www.script-tutorials.com/demos/360/images/clouds3.png) repeat top center;
  z-index:1002;
-webkit-animation:move-clouds-back 200s linear infinite;
animation:move-clouds-back 200s linear infinite;
}
.privacy {
  color: white;
  max-width: 600px;
  margin: 0 auto;
  padding: 5vmin 0;
}
.privacy-logo {
  height: 10vmin;
}
.privacy p {
  font-size: .5em;
  padding-top: 2vmin;
}
.help-question {
  font-size: .75em;
  padding-top: 2vmin;
}
.privacy-list {
  list-style: none;
  padding-top: 2vmin;
}
.privacy a {
  color: greenyellow;
}
.privacy a:hover {
  color: rgb(153, 255, 0);
  text-shadow: 0 0 5px #eeff00;
}
.privacy a {
  color: rgb(151, 197, 81);
}
