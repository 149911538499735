.footer-bar {
  z-index: 1004;
  padding-bottom: 2vmin;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: space-around;
  font-size: .6em;
}
.footer-landing {
  position: absolute;
  bottom: 1%;
  left: 0;
  right: 0;
}
.footer-link {
  color: greenyellow;
  text-decoration: none;
}
.footer-link:hover {
  color: rgb(153, 255, 0);
  text-shadow: 0 0 5px #eeff00;
}
.footer-link:active {
  color: rgb(123, 165, 60);
}