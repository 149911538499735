.privacy {
  color: white;
  max-width: 600px;
  margin: 0 auto;
  padding: 5vmin 0;
}
.privacy-logo {
  height: 10vmin;
}
.privacy p {
  font-size: .5em;
  padding-top: 2vmin;
}
.help-question {
  font-size: .75em;
  padding-top: 2vmin;
}
.privacy-list {
  list-style: none;
  padding-top: 2vmin;
}
.privacy a {
  color: greenyellow;
}
.privacy a:hover {
  color: rgb(153, 255, 0);
  text-shadow: 0 0 5px #eeff00;
}
.privacy a {
  color: rgb(151, 197, 81);
}